import { DocumentData, Query } from "firebase/firestore";
import { CustomerInterface } from "../../../Customers/Redux/Reducers/CustomersReducer";
import { EmployeeInterface } from "../../../Employees/Redux/Reducers/EmployeeReducer";
import { ProdutoInterface } from "../../../Storage/Redux/Reducers/StorageReducer";
import * as actionTypes from "../Types";

export interface SaleProdutoInterface extends ProdutoInterface {}
export interface SaleEmployeeInterface extends EmployeeInterface {}
export interface SaleProps {
  id: number;
  numeroVenda: number;
  funcionario?: SaleEmployeeInterface;
  cliente?: CustomerInterface;
  dataVenda: any;
  precoVenda: number;
  valorVenda: number;
  formaPagamento: string;
  produtos: Array<SaleProdutoInterface>;
}
interface Feedback {
  title: string;
  text: string;
  type: "success" | "error" | "warning" | "info" | "question";
  status: boolean;
}

interface State {
  data: {
    vendas: Array<SaleProps>;
    vendaSelecionada?: SaleProps;
    carregando: boolean;
    proximaQuery: Query<DocumentData> | undefined;
    feedback?: Feedback;
  };
}

export const initialState: State = {
  data: {
    vendas: [],
    carregando: false,
    proximaQuery: undefined,
  },
};

const ReducerVendas = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SALES:
      return {
        ...state,
        data: {
          ...state.data,
          vendas: action.data.vendas,
          proximaQuery: action.data.proximaQuery,
        },
      } as State;
    case actionTypes.SET_SALE:
      return {
        ...state,
        data: {
          ...state.data,
          vendaSelecionada: action.data,
        },
      } as State;
    case actionTypes.SET_SALES_LOADING:
      return {
        ...state,
        carregando: action.data,
      } as State;
    case actionTypes.SET_SALE_CUSTOMER:
      return {
        ...state,
        cliente: action.data,
      } as State;
    case actionTypes.SET_SALE_EMPLOYEE:
      return {
        ...state,
        funcionario: action.data,
      } as State;
    case actionTypes.CLEAN_SALES:
      return {
        ...initialState,
      } as State;
    case actionTypes.SET_VENDA_FEEDBACK:
      return {
        ...state,
        data: { ...state.data, feedback: action.data },
      };
    default:
      return state;
  }
};

export default ReducerVendas;
