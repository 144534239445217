import { DocumentData, Query } from "firebase/firestore";
import { CustomerInterface } from "../../../Customers/Redux/Reducers/CustomersReducer";
import { ProdutoInterface } from "../../../Storage/Redux/Reducers/StorageReducer";
import * as actionTypes from "../Types";

export interface SaleOnCreditProdutoInterface extends ProdutoInterface {}
export interface SaleOnCreditCustomerInterface extends CustomerInterface {}
export interface SaleOnCreditProps {
  id: number;
  cliente?: SaleOnCreditCustomerInterface;
  dataVendaFiado: any;
  precoVendaFiado: number;
  valorVendaFiado: number;
  formaPagamento: string;
  produtos: Array<SaleOnCreditProdutoInterface>;
}
interface Feedback {
  title: string;
  text: string;
  type: "success" | "error" | "warning" | "info" | "question";
  status: boolean;
}

interface State {
  data: {
    vendasFiado: Array<SaleOnCreditProps>;
    vendaFiadoSelecionada?: SaleOnCreditProps;
    carregando: boolean;
    proximaQuery: Query<DocumentData> | undefined;
    feedback?: Feedback;
  };
}

export const initialState: State = {
  data: {
    vendasFiado: [],
    carregando: false,
    proximaQuery: undefined,
  },
};

const ReducerVendasFiado = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SALESONCREDIT:
      return {
        ...state,
        data: {
          ...state.data,
          vendasFiado: action.data.vendasFiado,
          proximaQuery: action.data.proximaQuery,
        },
      } as State;
    case actionTypes.SET_SALEONCREDIT:
      return {
        ...state,
        data: {
          ...state.data,
          vendaFiadoSelecionada: action.data,
        },
      } as State;
    case actionTypes.SET_SALESONCREDIT_LOADING:
      return {
        ...state,
        carregando: action.data,
      } as State;
    case actionTypes.SET_SALEONCREDIT_CUSTOMER:
      return {
        ...state,
        cliente: action.data,
      } as State;
    case actionTypes.CLEAN_SALESONCREDIT:
      return {
        ...initialState,
      } as State;
    case actionTypes.SET_VENDAFIADO_FEEDBACK:
      return {
        ...state,
        data: { ...state.data, feedback: action.data },
      };
    default:
      return state;
  }
};

export default ReducerVendasFiado;
