import { DocumentData, Query } from "firebase/firestore";
import * as actionTypes from "../Types";

export interface EmployeeInterface {
  id: string;
  nomeFuncionario: string;
  ativo: boolean;
  dataCriacao: any;
  dataAtualizacao: any;
}

interface Feedback {
  title: string;
  text: string;
  type: string;
  status: boolean;
}

interface State {
  data: {
    funcionarios: Array<EmployeeInterface>;
    selectedEmployee?: EmployeeInterface;
    funcionariosAtivos: Array<EmployeeInterface>;
    proximaQuery: Query<DocumentData> | undefined;
    carregando: boolean;
    buscarNovos: boolean;
    feedback?: Feedback;
  };
}

export const initialState: State = {
  data: {
    funcionarios: [],
    funcionariosAtivos: [],
    proximaQuery: undefined,
    carregando: false,
    buscarNovos: true,
  },
};

const funcionariosReducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_EMPLOYEES:
      return {
        ...state,
        data: {
          ...state.data,
          funcionarios: action.data.funcionarios,
          proximaQuery: action.data.proximaQuery,
          buscarNovos: action.data.buscarNovos,
        },
      } as State;
    case actionTypes.CLEAN_EMPLOYEES:
      return {
        ...state,
        data: { ...initialState.data },
      } as State;
    case actionTypes.SET_EMPLOYEE:
      return {
        ...state,
        data: { ...state.data, selectedEmployee: action.data },
      } as State;
    case actionTypes.SET_EMPLOYEES_LOADING:
      return {
        ...state,
        data: { ...state.data, carregando: action.value },
      } as State;
    case actionTypes.SET_FUNCIONARIOS_ATIVOS:
      return {
        ...state,
        data: { ...state.data, funcionariosAtivos: action.data },
      };
    case actionTypes.SET_EMPLOYEE_FEEDBACK:
      return {
        ...state,
        data: { ...state.data, feedback: action.data },
      };
    default:
      return state;
  }
};

export default funcionariosReducer;
