import * as actionTypes from "../Types";

export interface ProdutoInterface {
  id: string;
  nomeProduto: string;
  precoCompra: number;
  precoVenda: number;
  dataCriacao: any;
  dataAtualizacao: any;
  quantidade: number;
  tipoProduto?: string;
}
interface Feedback {
  title: string;
  text: string;
  type: string;
  status: boolean;
}

interface State {
  data: {
    storage: Array<ProdutoInterface>;
    selectedProduct?: ProdutoInterface;
    loading: boolean;
    feedback?: Feedback;
  };
}

export const initialState: State = {
  data: {
    storage: [],
    loading: false,
  },
};

const StorageReducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_STORAGE:
      return {
        ...state,
        data: { ...initialState.data, storage: action.data },
      } as State;
    case actionTypes.CLEAN_STORAGE:
      return {
        ...state,
        data: { ...initialState.data },
      } as State;
    case actionTypes.SET_PRODUCT:
      return {
        ...state,
        data: { ...state.data, selectedProduct: action.data },
      } as State;
    case actionTypes.SET_STORAGE_LOADING:
      return {
        ...state,
        loading: action.value,
      } as State;
    case actionTypes.SET_PRODUTO_FEEDBACK:
      return {
        ...state,
        data: { ...state.data, feedback: action.data },
      };
    default:
      return state;
  }
};

export default StorageReducer;
