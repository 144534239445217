import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useSelector } from "../../Redux";
import Header from "../Components/Header";
import Navbar from "../Components/Navbar";
import Body from "./Body";
import {
  Container,
  ContainerFlex,
  ContainerRender,
  GlobalStyle,
  LoadingContainer,
} from "./Navigation_style";

const LoginPage = lazy(() => import("../Login/Page"));
const SalesPage = lazy(() => import("../../Modules/Sales/Page"));
const SalesOnCreditPage = lazy(
  () => import("../../Modules/SalesOnCredit/Page")
);
const ServiceOrdersPage = lazy(
  () => import("../../Modules/ServiceOrders/Page")
);
const StoragePage = lazy(() => import("../../Modules/Storage/Page"));
const StorageWithoutBarCodePage = lazy(
  () => import("../../Modules/StorageWithoutBarCode/Page")
);
const EmployeesPage = lazy(() => import("../../Modules/Employees/Page"));
const CustomersPage = lazy(() => import("../../Modules/Customers/Page"));
const SummaryPage = lazy(() => import("../../Modules/Summary/Page"));

const ProductWithoutBarCodeRegisterForm = lazy(
  () => import("../../Modules/StorageWithoutBarCode/Components/Form")
);
const ProductRegisterForm = lazy(
  () => import("../../Modules/Storage/Components/Form")
);
const EmployeeRegisterForm = lazy(
  () => import("../../Modules/Employees/Components/Form")
);
const CustomerRegisterForm = lazy(
  () => import("../../Modules/Customers/Components/Form")
);
const SaleRegisterForm = lazy(
  () => import("../../Modules/Sales/Components/Form")
);
const SaleOnCreditRegisterForm = lazy(
  () => import("../../Modules/SalesOnCredit/Components/Form")
);
const ServiceOrderRegisterForm = lazy(
  () => import("../../Modules/ServiceOrders/Components/Form")
);

export default function Navigation() {
  const [toggled, setToggled] = useState(false);
  const { user, loadingAuth } = useSelector(
    (state) => state.Authentication.Login
  );
  const isLoggedIn = !!user;
  let modulos //= [
  //   "resumo",
  //   "vendasFiado",
  //   "vendaFiado",
  //   "novaVendaFiado",
  //   "estoqueWithoutBarCode",
  //   "produtoWithoutBarCode",
  //   "clientes",
  //   "cliente",
  // ];
  if (typeof user?.userData?.data === "function") {
    console.log('user', user.userData.data())
    modulos = user.userData.data().modulos;
  }

  const routes = (
    <Switch>
      {/* funcionaios e funcionario */}
      {modulos?.includes("funcionarios") && (
        <Route path="/funcionarios" exact component={EmployeesPage} />
      )}
      {modulos?.includes("funcionario") && (
        <Route path="/funcionario" exact component={EmployeeRegisterForm} />
      )}

      {/* resumo */}
      {modulos?.includes("resumo") && (
        <Route path="/resumo" exact component={SummaryPage} />
      )}

      {/* vendas, venda e novaVenda*/}
      {modulos?.includes("vendas") && (
        <Route path="/vendas" exact component={SalesPage} />
      )}
      {modulos?.includes("venda") && (
        <Route path="/venda" exact component={SaleRegisterForm} />
      )}
      {modulos?.includes("novaVenda") && (
        <Route path="/nova-venda" exact component={SaleRegisterForm} />
      )}

      {/* ordensServico e ordemServico */}
      {modulos?.includes("ordensServico") && (
        <Route path="/ordens-servico" exact component={ServiceOrdersPage} />
      )}
      {modulos?.includes("ordemServico") && (
        <Route
          path="/ordem-servico"
          exact
          component={ServiceOrderRegisterForm}
        />
      )}

      {/* vendasFiado, vendaFiado e novaVendaFiado*/}
      {modulos?.includes("vendasFiado") && (
        <Route path="/vendas-fiado" exact component={SalesOnCreditPage} />
      )}
      {modulos?.includes("vendaFiado") && (
        <Route path="/venda-fiado" exact component={SaleOnCreditRegisterForm} />
      )}
      {modulos?.includes("novaVendaFiado") && (
        <Route
          path="/nova-venda-fiado"
          exact
          component={SaleOnCreditRegisterForm}
        />
      )}

      {/* estoque e produto */}
      {modulos?.includes("estoque") && (
        <Route path="/estoque" exact component={StoragePage} />
      )}
      {modulos?.includes("produto") && (
        <Route path="/produto" exact component={ProductRegisterForm} />
      )}

      {/* estoque e produto */}
      {modulos?.includes("estoqueWithoutBarCode") && (
        <Route
          path="/estoque-withoutbarcode"
          exact
          component={StorageWithoutBarCodePage}
        />
      )}
      {modulos?.includes("produtoWithoutBarCode") && (
        <Route
          path="/produto-withoutbarcode"
          exact
          component={ProductWithoutBarCodeRegisterForm}
        />
      )}

      {/* clientes e cliente */}
      {modulos?.includes("clientes") && (
        <Route path="/clientes" exact component={CustomersPage} />
      )}
      {modulos?.includes("cliente") && (
        <Route path="/cliente" exact component={CustomerRegisterForm} />
      )}

      {/* redirect */}
      {modulos?.includes("resumo") ? (
        <Redirect from="/" to="/resumo" />
      ) : modulos?.includes("vendas") ? (
        <Redirect from="/" to="/vendas" />
      ) : null}
    </Switch>
  );
  return (
    <Router>
      <Suspense
        fallback={
          <LoadingContainer>
            <CircularProgress size={100} />
          </LoadingContainer>
        }
      >
        {isLoggedIn ? (
          loadingAuth ? (
            <LoadingContainer>
              <CircularProgress size={100} />
            </LoadingContainer>
          ) : (
            <>
              <GlobalStyle />
              <ContainerFlex>
                <Body>
                  <Navbar toggled={toggled} setToggled={setToggled} />
                  <Container>
                    <Header toggled={toggled} setToggled={setToggled} />
                    <ContainerRender>{routes}</ContainerRender>
                  </Container>
                </Body>
              </ContainerFlex>
            </>
          )
        ) : (
          <Switch>
            <Route path="/recuperar-senha" component={LoginPage} />
            <Route path="/login" component={LoginPage} />
            <Redirect path="/" to="/login" />
          </Switch>
        )}
      </Suspense>
    </Router>
  );
}
