import {
  StorageOutlined,
  ShoppingCartOutlined,
  AttachMoneyOutlined,
  AssignmentIndOutlined,
  FaceOutlined,
  ReceiptOutlined,
} from "@material-ui/icons";
import { Menu, MenuItem, SubMenu, ProSidebar } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "../../../Redux";
import TechBox from "../../../Assets/logo/logo.svg";
import { Logo, LogoDiv } from "./Navbar_style";

const Navbar = ({ toggled, setToggled }) => {
  const { user } = useSelector((state) => state.Authentication.Login);
  let modulos // = [
  //   "resumo",
  //   "vendasFiado",
  //   "vendaFiado",
  //   "novaVendaFiado",
  //   "estoqueWithoutBarCode",
  //   "produtoWithoutBarCode",
  //   "clientes",
  //   "cliente",
  // ];
  if (typeof user?.userData?.data === "function") {
    modulos = user.userData.data().modulos;
  }
  const history = useHistory();

  const onNavbarSelect = (selectedMenuParam) => {
    setToggled(false);
    history.push({
      pathname: `/${selectedMenuParam}`,
    });
  };

  return (
    <ProSidebar onToggle={setToggled} breakPoint="md" toggled={toggled}>
      <LogoDiv>
        <Logo src={TechBox} alt="Logo" onClick={() => {}} />
      </LogoDiv>

      <Menu iconShape="square">
        {/* resumo */}
        {modulos?.includes("resumo") && (
          <MenuItem
            onClick={() => onNavbarSelect("resumo")}
            icon={<AttachMoneyOutlined />}
          >
            Resumo
          </MenuItem>
        )}

        {/* vendas e venda */}
        {modulos?.includes("vendas") && modulos?.includes("venda") ? (
          <SubMenu title="Vendas" icon={<ShoppingCartOutlined />}>
            <MenuItem onClick={() => onNavbarSelect("vendas")}>
              Vendas por dia
            </MenuItem>
            <MenuItem onClick={() => onNavbarSelect("venda")}>
              Nova venda
            </MenuItem>
          </SubMenu>
        ) : modulos?.includes("vendas") ? (
          <MenuItem
            onClick={() => onNavbarSelect("vendas")}
            icon={<ShoppingCartOutlined />}
          >
            Vendas por dia
          </MenuItem>
        ) : modulos?.includes("novaVenda") ? (
          <MenuItem
            onClick={() => onNavbarSelect("nova-venda")}
            icon={<ShoppingCartOutlined />}
          >
            Nova venda
          </MenuItem>
        ) : null}

        {/* vendasFiado e novaVenda */}
        {modulos?.includes("vendasFiado") &&
        modulos?.includes("novaVendaFiado") ? (
          <SubMenu title="Vendas" icon={<ShoppingCartOutlined />}>
            <MenuItem onClick={() => onNavbarSelect("vendas-fiado")}>
              Vendas por dia
            </MenuItem>
            <MenuItem onClick={() => onNavbarSelect("nova-venda-fiado")}>
              Nova venda
            </MenuItem>
          </SubMenu>
        ) : modulos?.includes("vendasFiado") ? (
          <MenuItem
            onClick={() => onNavbarSelect("vendas-fiado")}
            icon={<ShoppingCartOutlined />}
          >
            Vendas por dia
          </MenuItem>
        ) : modulos?.includes("novaVendaFiado") ? (
          <MenuItem
            onClick={() => onNavbarSelect("nova-venda-fiado")}
            icon={<ShoppingCartOutlined />}
          >
            Nova venda
          </MenuItem>
        ) : null}

        {/* ordensServico e ordemServico */}
        {modulos?.includes("ordensServico") &&
        modulos?.includes("ordemServico") ? (
          <SubMenu title="OSs" icon={<ReceiptOutlined />}>
            <MenuItem onClick={() => onNavbarSelect("ordens-servico")}>
              OSs por período
            </MenuItem>
            <MenuItem onClick={() => onNavbarSelect("ordem-servico")}>
              Nova OS
            </MenuItem>
          </SubMenu>
        ) : modulos?.includes("ordensServico") ? (
          <MenuItem
            onClick={() => onNavbarSelect("ordens-servico")}
            icon={<ReceiptOutlined />}
          >
            OSs por período
          </MenuItem>
        ) : modulos?.includes("ordemServico") ? (
          <MenuItem
            onClick={() => onNavbarSelect("ordem-servico")}
            icon={<ReceiptOutlined />}
          >
            Nova OS
          </MenuItem>
        ) : null}

        {/* estoque e produto */}
        {modulos?.includes("estoque") && modulos?.includes("produto") ? (
          <SubMenu title="Estoque" icon={<StorageOutlined />}>
            <MenuItem onClick={() => onNavbarSelect("estoque")}>
              Estoque
            </MenuItem>
            <MenuItem onClick={() => onNavbarSelect("produto")}>
              Novo Produto
            </MenuItem>
          </SubMenu>
        ) : modulos?.includes("estoque") ? (
          <MenuItem
            onClick={() => onNavbarSelect("estoque")}
            icon={<StorageOutlined />}
          >
            Estoque
          </MenuItem>
        ) : modulos?.includes("produto") ? (
          <MenuItem
            onClick={() => onNavbarSelect("produto")}
            icon={<StorageOutlined />}
          >
            Novo Produto
          </MenuItem>
        ) : null}

        {/* estoqueWithoutBarCode e produtoWithoutBarCode */}
        {modulos?.includes("estoqueWithoutBarCode") &&
        modulos?.includes("produtoWithoutBarCode") ? (
          <SubMenu title="Estoque" icon={<StorageOutlined />}>
            <MenuItem onClick={() => onNavbarSelect("estoque-withoutbarcode")}>
              Estoque
            </MenuItem>
            <MenuItem onClick={() => onNavbarSelect("produto-withoutbarcode")}>
              Novo Produto
            </MenuItem>
          </SubMenu>
        ) : modulos?.includes("estoqueWithoutBarCode") ? (
          <MenuItem
            onClick={() => onNavbarSelect("estoque-withoutbarcode")}
            icon={<StorageOutlined />}
          >
            Estoque
          </MenuItem>
        ) : modulos?.includes("produtoWithoutBarCode") ? (
          <MenuItem
            onClick={() => onNavbarSelect("produto-withoutbarcode")}
            icon={<StorageOutlined />}
          >
            Novo Produto
          </MenuItem>
        ) : null}

        {/* funcionarios e funcionario */}
        {modulos?.includes("funcionarios") &&
        modulos?.includes("funcionario") ? (
          <SubMenu title="Funcionários" icon={<AssignmentIndOutlined />}>
            <MenuItem onClick={() => onNavbarSelect("funcionarios")}>
              Funcionários
            </MenuItem>
            <MenuItem onClick={() => onNavbarSelect("funcionario")}>
              Novo Funcionário
            </MenuItem>
          </SubMenu>
        ) : modulos?.includes("funcionarios") ? (
          <MenuItem
            onClick={() => onNavbarSelect("funcionarios")}
            icon={<AssignmentIndOutlined />}
          >
            Funcionários
          </MenuItem>
        ) : modulos?.includes("funcionario") ? (
          <MenuItem
            onClick={() => onNavbarSelect("funcionario")}
            icon={<AssignmentIndOutlined />}
          >
            Novo Funcionário
          </MenuItem>
        ) : null}

        {/* clientes e cliente */}
        {modulos?.includes("clientes") && modulos?.includes("cliente") ? (
          <SubMenu title="Clientes" icon={<FaceOutlined />}>
            <MenuItem onClick={() => onNavbarSelect("clientes")}>
              Clientes
            </MenuItem>
            <MenuItem onClick={() => onNavbarSelect("cliente")}>
              Novo Cliente
            </MenuItem>
          </SubMenu>
        ) : modulos?.includes("clientes") ? (
          <MenuItem onClick={() => onNavbarSelect("clientes")}>
            Clientes
          </MenuItem>
        ) : modulos?.includes("cliente") ? (
          <MenuItem onClick={() => onNavbarSelect("cliente")}>
            Novo Cliente
          </MenuItem>
        ) : null}
      </Menu>
    </ProSidebar>
  );
};

export default Navbar;
