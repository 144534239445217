import moment from "moment";
import { getCurrentISODate } from "../../../../Utils/CommonFunctions";
import { CustomerInterface } from "../../../Customers/Redux/Reducers/CustomersReducer";
import { EmployeeInterface } from "../../../Employees/Redux/Reducers/EmployeeReducer";
import * as actionTypes from "../Types";

export interface ServiceOrderInterface {
  id: string;
  numeroOs: number;
  cliente?: CustomerInterface;
  funcionario?: EmployeeInterface;
  nomeEquipamento: string;
  marca: string;
  modelo: string;
  numeroSerie: string;
  condicoes: string;
  defeitos: string;
  acessorios: string;
  solucao: string;
  laudoTecnico: string;
  termoGarantia: string;
  observacoes: string;
  dataEntrada: any;
  dataSaida?: any;
  valor: number;
  status: string;
  formaPagamento: string;
}

interface State {
  data: {
    serviceOrders: Array<ServiceOrderInterface>;
    cpfOs: string;
    selectedOs: ServiceOrderInterface;
    isShowing: boolean;
  };
  date: string;
  loading: boolean;
}

export const initialState: State = {
  data: {
    serviceOrders: [],
    cpfOs: "",
    selectedOs: {
      id: "",
      numeroOs: 0,
      nomeEquipamento: "",
      marca: "",
      modelo: "",
      numeroSerie: "",
      condicoes: "",
      defeitos: "",
      acessorios: "",
      solucao: "",
      laudoTecnico: "",
      termoGarantia: "",
      observacoes: "",
      dataEntrada: null,
      dataSaida: null,
      valor: 0,
      status: "",
      formaPagamento: "",
    },
    isShowing: false,
  },
  date: moment(getCurrentISODate(new Date())).format("YYYY-MM-DD"),
  loading: false,
};

const ServiceOrdersReducer = (state: State = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SERVICE_ORDERS:
      return {
        ...state,
        data: {
          ...state.data,
          serviceOrders: action.data,
        },
      } as State;
    case actionTypes.SET_CPF_SERVICE_ORDERS:
      return {
        ...state,
        data: {
          ...state.data,
          cpfOs: action.data,
        },
      } as State;
    case actionTypes.SET_ID_SERVICE_ORDERS:
      return {
        ...state,
        data: {
          ...state.data,
          idSelectedOs: action.data,
        },
      } as State;
    case actionTypes.SET_SHOW_SERVICE_ORDERS:
      return {
        ...state,
        data: {
          ...state.data,
          isShowing: action.data,
        },
      } as State;
    case actionTypes.SET_DATE_OS:
      return {
        ...state,
        date: action.data,
      } as State;
    case actionTypes.SET_INITIAL_OS_PERIOD:
      return {
        ...state,
        date: moment(getCurrentISODate(new Date())).format("YYYY-MM-DD"),
      };
    case actionTypes.SET_SERVICE_ORDERS_LOADING:
      return {
        ...state,
        loading: action.value,
      } as State;
    default:
      return state;
  }
};

export default ServiceOrdersReducer;
